import React from 'react'
import LandingPage from '@/modules/landing/LandingPage'
import ThemeManager from '@/core/theme/ThemeManager'
import Modal from 'react-modal'
import { AppContextProvider } from '@/core/contexts/AppContext'
import { Toaster } from 'react-hot-toast'

Modal.setAppElement('#root')

function App() {
  return (
    <ThemeManager>
      <AppContextProvider>
        <LandingPage />
        <Toaster />
      </AppContextProvider>
    </ThemeManager>
  )
}

export default App
