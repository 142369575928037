import styled from 'styled-components'
import starsImage from '@/assets/images/landing-sections/stars.svg'

export const Container = styled.div`
  margin-bottom: 7%;
  margin-left: 10%;
  margin-right: 10%;
  display: flex;
  flex-direction: ${({ theme }) =>
    theme.helper.isSmallDevice ? 'column' : 'row'};
  gap: ${({ theme }) => theme.sizes.size5}px;
`

export const Section = styled.div`
  flex: 1;
`

export const ImageSection = styled.div`
  text-align: center;
  background-image: url('${starsImage}');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: ${({ theme }) =>
    theme.helper.isSmallDevice ? 'column' : 'row'};
  gap: ${({ theme }) => theme.sizes.size5}px;
`

export const Image = styled.img`
  width: 100%;
  height: 700px;
  align-self: center;
`

export const Link = styled.div`
  color: ${({ theme }) => theme.colors.secondary};
  align-self: center;
  display: flex;
  flex-direction: row;
  align-items: center;
`
