import { Trans } from 'react-i18next'
import { NavigationHeader } from '@/core/components/layout'
import { SecondarySpanText } from '@/core/components/texts'
import {
  BannerSubTitle,
  BannerTitle,
  Container,
  DownloadsContainer,
  Overlay,
  Section,
} from '@/modules/landing/components/main-section/MainSection.styles'
import appStoreImage from '@/assets/images/downloads/app-store.svg'

export const MainSection = () => {
  return (
    <Container>
      <Overlay />
      <NavigationHeader />
      <Section>
        <BannerTitle>
          <Trans
            i18nKey={'mainSection.title'}
            components={{
              secondary: <SecondarySpanText />,
            }}
          />
        </BannerTitle>
        <BannerSubTitle>
          <Trans i18nKey={'mainSection.subTitle'} />
        </BannerSubTitle>
        <DownloadsContainer>
          <a href="#">
            <img src={appStoreImage} alt="App Store" />
          </a>
        </DownloadsContainer>
      </Section>
    </Container>
  )
}

export default MainSection
