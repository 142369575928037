import styled from 'styled-components'
import bannerImage from '@/assets/images/banner.gif'
import { BigTitleText, HintText } from '@/core/components/texts'

export const Container = styled.div`
  position: relative;
  background-image: url('${bannerImage}');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 80vh;
  margin-bottom: 50px;
`

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  pointer-events: none;
`

export const Section = styled.div`
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const BannerTitle = styled(BigTitleText)`
  color: ${({ theme }) => theme.colors.white};
`

export const BannerSubTitle = styled(HintText)`
  color: ${({ theme }) => theme.colors.white};
`

export const DownloadsContainer = styled.div`
  margin-top: 5%;
  padding-bottom: 50px;
  display: flex;
  gap: ${({ theme }) => theme.sizes.size1}px;
  justify-content: center;
  ${({ theme }) =>
    theme.helper.isSmallDevice &&
    `
    align-items: center;
    flex-direction: column;
`}
`
