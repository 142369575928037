import styled from 'styled-components'

export const HeaderContainer = styled.div`
  display: flex;
`

export const LogoContainer = styled.div`
  display: flex;
  position: absolute;
  left: 10%;
`

export const ButtonContainer = styled.div`
  min-width: 200px;
  position: absolute;
  top: 2%;
  right: 10%;
  ${({ theme }) =>
    theme.helper.isSmallDevice &&
    `
    min-width: 150px;
    right: 10px;
`}
`

export const AppName = styled.h3`
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.bold};
`
