import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'

const firebaseConfig = {
  apiKey: 'AIzaSyCv0ZJiWokDIquL9dNOmrUeAGeBIjZ9rpk',
  authDomain: 'thespotter-fa286.firebaseapp.com',
  projectId: 'thespotter-fa286',
  storageBucket: 'thespotter-fa286.appspot.com',
  messagingSenderId: '1047606936555',
  appId: '1:1047606936555:web:302f0166e6244494dbcfae',
  measurementId: 'G-2ED3YYWX4T',
}

const app = initializeApp(firebaseConfig)
const DB = getFirestore(app)

export { DB }
