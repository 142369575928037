import { Trans } from 'react-i18next'
import { HintText, PrimarySpanText, TitleText } from '@/core/components/texts'
import {
  Container,
  Image,
  Section,
  SectionContainer,
} from '@/modules/landing/components/features/Features.styles'
import features1Image from '@/assets/images/landing-sections/features1.svg'
import features2Image from '@/assets/images/landing-sections/features2.svg'

export const Features = () => {
  return (
    <Container>
      <SectionContainer>
        <Section style={{ alignSelf: 'center' }}>
          <TitleText>
            <Trans
              i18nKey={'features.title'}
              components={{
                secondary: <PrimarySpanText />,
              }}
            />
          </TitleText>
          <HintText>
            <Trans i18nKey={'features.subTitle'} />
          </HintText>
        </Section>
        <Section style={{ textAlign: 'center' }}>
          <Image src={features1Image} alt="logo" />
        </Section>
      </SectionContainer>

      <SectionContainer style={{ marginTop: '2%' }}>
        <Section style={{ textAlign: 'center' }}>
          <Image src={features2Image} alt="logo" />
        </Section>
        <Section style={{ alignSelf: 'center' }}>
          <HintText>
            <Trans i18nKey={'features.subTitle2'} />
          </HintText>
        </Section>
      </SectionContainer>
    </Container>
  )
}

export default Features
