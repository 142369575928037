import { Trans } from 'react-i18next'
import {
  HintBoldText,
  HintText,
  MediumTitleText,
  SecondarySpanText,
  TitleText,
} from '@/core/components/texts'
import {
  Container,
  Section,
  SectionContainer,
} from '@/modules/landing/components/tools-goal/ToolsGoal.styles'
import shareImage from '@/assets/images/goal-tools/share.svg'
import faceImage from '@/assets/images/goal-tools/face.svg'
import streamImage from '@/assets/images/goal-tools/streamline-network.svg'
import i18n from '@/core/translation'

export const ToolsGoal = () => {
  return (
    <Container>
      <TitleText style={{ textAlign: 'center' }}>
        <Trans
          i18nKey={'toolsGoalSection.title'}
          components={{
            secondary: <SecondarySpanText />,
          }}
        />
      </TitleText>
      <HintBoldText style={{ textAlign: 'center' }}>
        <Trans i18nKey={'toolsGoalSection.subTitle'} />
      </HintBoldText>
      <SectionContainer>
        <Section>
          <img src={shareImage} alt="Apple" />
          <MediumTitleText>
            {i18n.t('toolsGoalSection.section1Title')}
          </MediumTitleText>
          <HintBoldText>{i18n.t('toolsGoalSection.section1Hint')}</HintBoldText>
          <br></br>
          <HintText>{i18n.t('toolsGoalSection.section1SubTitle')}</HintText>
        </Section>
        <Section>
          <img src={faceImage} alt="Move" />
          <MediumTitleText>
            {i18n.t('toolsGoalSection.section2Title')}
          </MediumTitleText>
          <HintBoldText>{i18n.t('toolsGoalSection.section2Hint')}</HintBoldText>
          <br></br>
          <HintText>{i18n.t('toolsGoalSection.section2SubTitle')}</HintText>
        </Section>
        <Section>
          <img src={streamImage} alt="Move" />
          <MediumTitleText>
            {i18n.t('toolsGoalSection.section3Title')}
          </MediumTitleText>
          <HintBoldText>{i18n.t('toolsGoalSection.section1Hint')}</HintBoldText>
          <br></br>
          <HintText>{i18n.t('toolsGoalSection.section3SubTitle')}</HintText>
        </Section>
      </SectionContainer>
    </Container>
  )
}

export default ToolsGoal
