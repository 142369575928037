import Features from '@/modules/landing/components/features/Features'
import MainSection from '@/modules/landing/components/main-section/MainSection'
import MobileApp from '@/modules/landing/components/mobile-app/MobileApp'
import ToolsGoal from '@/modules/landing/components/tools-goal/ToolsGoal'
import TryApp from '@/modules/landing/components/try-app/TryApp'
import JoinWaitListModal from '@/modules/landing/components/modals/join-wait-list/JoinWaitListModal'

export const LandingPage = () => {
  return (
    <>
      <MainSection />
      <MobileApp />
      <ToolsGoal />
      <Features />
      <TryApp />
      <JoinWaitListModal />
      <p style={{ textAlign: 'center', marginBottom: '5%' }}>
        Copyright © 2023 - All Rights Reserved.
      </p>
    </>
  )
}

export default LandingPage
