import i18n from '@/core/translation'
import {
  AppName,
  ButtonContainer,
  HeaderContainer,
  LogoContainer,
} from '@/core/components/layout/navigation-header/NavigationHeader.styles'
import logo from '@/assets/images/logo.svg'
import RegularButton from '@/core/components/buttons/regular-button/RegularButton'
import { useAppContext } from '@/core/contexts/AppContext'

export const NavigationHeader = () => {
  const { setIsJoinWaitListModalOpened } = useAppContext()

  const joinWaitList = () => {
    setIsJoinWaitListModalOpened(true)
  }

  return (
    <HeaderContainer>
      <LogoContainer>
        <img style={{ paddingRight: '15px' }} src={logo} alt="Logo" />
        <AppName>{i18n.t('appName')}</AppName>
      </LogoContainer>
      <ButtonContainer>
        <RegularButton
          onClick={joinWaitList}
          type="secondary"
          title={i18n.t('actions.joinWaitList')}
        ></RegularButton>
      </ButtonContainer>
    </HeaderContainer>
  )
}

export default NavigationHeader
