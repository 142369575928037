import { TitleText } from '@/core/components/texts'
import styled from 'styled-components'

export const Container = styled.div`
  margin-bottom: 7%;
  margin-left: 10%;
  margin-right: 10%;
  padding: 4%;
  border-radius: 50px;
  display: flex;
  background-color: ${({ theme }) => theme.colors.main};
  flex-direction: ${({ theme }) =>
    theme.helper.isSmallDevice ? 'column' : 'row'};
  gap: ${({ theme }) => theme.sizes.size5}px;
`

export const Section = styled.div`
  flex: 1;
`

export const Image = styled.img`
  width: 100%;
  position: relative;
  bottom: 0;
  align-self: center;
`

export const TryAppTitle = styled(TitleText)`
  color: ${({ theme }) => theme.colors.white};
`
